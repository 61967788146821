body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease-in-out forwards;
}

/* WebKit Browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px; /* Scrollbar width */
}

::-webkit-scrollbar-track {
  background: #000000; /* Black background for track */
  border: 1px solid #333; /* Subtle border */
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #FFD700, #DAA520); /* Gold gradient */
  border-radius: 10px;
  border: 2px solid #000; /* Black border around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #FFD700, #B8860B); /* Darker gold on hover */
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #FFD700 #000000; /* Gold thumb on black track */
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Add to your global CSS file */
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Custom scrollbar styling */
.scrollbar-thin {
  scrollbar-width: thin;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: rgba(216, 180, 54, 0.5);
  border-radius: 10px;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background: rgba(216, 180, 54, 0.7);
}

/* Additional utility classes for the search modal */
.backdrop-blur-md {
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.text-shadow {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* Gradient text for premium look */
.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, #d8b436, #f0c04a);
}

/* Additional styles for the search modal responsiveness */
@media (max-width: 640px) {
  .search-modal-container {
    top: 5vh;
    max-height: 90vh;
  }
}

@media (max-height: 700px) {
  .search-modal-container {
    top: 5vh;
    max-height: 90vh;
  }
  
  .search-modal-content {
    max-height: 50vh;
  }
}

/* Make scrollbar more visible on mobile */
@media (max-width: 768px) {
  .scrollbar-thin::-webkit-scrollbar {
    width: 4px;
  }
}

/* Prevent body scroll when modal is open */
body.modal-open {
  overflow: hidden;
}

/* Add these premium active navigation styles */
.shadow-gold {
  box-shadow: 0 0 10px rgba(218, 165, 32, 0.4), 0 0 5px rgba(255, 215, 0, 0.6);
}

.active-nav-item {
  position: relative;
}

.active-nav-item::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #FFD700, #DAA520);
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(255, 215, 0, 0.5);
}

/* Mobile active nav styles */
.mobile-active-nav {
  background: linear-gradient(to right, rgba(218, 165, 32, 0.2), rgba(218, 165, 32, 0.05));
  border-left: 2px solid #DAA520;
}